export default function DecksContainer() {
  return (
    <div className="page-container">
      <div className="responsive-card">
        <h2 className="card-title-text">Decks</h2>
        <h5>Under Construction</h5>
      </div>
    </div>
  );
}
